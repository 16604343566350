<template>
  <Questionnaire
    class="p-mt-4"
    :readOnly="true"
    :show="true"
    :items="questions"
    v-if="is_question"
  />
  <p class="p-p-5 p-text-center" v-if="!is_question">No Questions</p>
</template>

<script>
import useI18n from '@/plugins/useI18n';
import Questionnaire from '@/components/Service/Questionnaire';
import { computed } from 'vue';

export default {
  components: {
    Questionnaire
  },
  props: {
    item: {
      type: Object
    }
  },
  setup(props) {
    const { i18n } = useI18n();

    const questions = computed(() => props.item.questions);
    const is_question = computed(() => props.item.is_question);

    return {
      i18n,
      questions,
      is_question
    };
  }
};
</script>

<style scoped>
::v-deep(.title) {
  font-size: 21px;
  font-weight: 400;
  line-height: 100%;
}
</style>